import { ArticleDetail } from "@/api/sdk"
import AspectRatio from "@/components/AspectRatio"
import classNames from "classnames"
import { memo } from "react"
import { twMerge } from "tailwind-merge"
import ArticleMetric from "./ArticleMetric"
import CategoryArticle from "./CategoryArticle"
import UserCreated from "../UserCreated"
import ImageWebpComponent from "@/components/ImageWebpComponent"
import { useInView } from "react-intersection-observer"
import ActionButtonsArticle from "./ActionButtonsArticle"
import { User } from "firebase/auth"
import { RoutedDialogLink, triggerRoutedDialog } from "@/providers/RoutedDialogProvider"
import useCustomRouter from "@/hooks/useCustomRouter"
import { useBrowserRouter } from "@/providers/BrowserRouterProvider"

export interface GalleryItemProps {
  data: ArticleDetail
  className?: string
  hiddenMenu?: boolean
  onClick?: () => void
  user?: User | null
  typeRedirectRouter?: "push" | "replace"
}

const ArticleItem = ({ data: article, className, onClick, user, hiddenMenu, typeRedirectRouter }: GalleryItemProps) => {
  const { ref, inView } = useInView({
    threshold: 0,
    rootMargin: "800px 0px 800px 0px",
  })
  const router = useCustomRouter()
  const historyPath = router.asPath

  return (
    <div
      key={article.id}
      data-id={article.id}
      className={twMerge(
        classNames("group cursor-pointer shrink flex flex-col relative gallery-item shadow-sm"),
        className,
      )}
      ref={ref}
      style={{
        transform: "none",
      }}
    >
      <RoutedDialogLink
        onClick={
          onClick
            ? () => {
                onClick()
              }
            : undefined
        }
        state={{
          articleId: article.id,
          filters: {
            historyPath,
          },
        }}
        name="articleDetailExplore"
        type={typeRedirectRouter}
        className="relative overflow-hidden rounded-t-2xl transform-gpu bg-atherGray-850 shadow-whiteAlpha-200 flex flex-col"
      >
        <AspectRatio ratio={16 / 8} className="aspect-[16/8] relative">
          <div className="relative w-full h-full overflow-hidden">
            <div className="absolute w-full top-0" style={{ top: `${Number(article.coverImageY)}%` }}>
              {inView && (
                <ImageWebpComponent
                  src={article.coverImageUrl ?? ""}
                  alt={article.title}
                  className="object-cover w-full block m-auto"
                />
              )}
            </div>
          </div>
          {inView && (
            <>
              <ArticleMetric article={article} />
              {!hiddenMenu && (
                <div className="absolute bottom-0 left-0 transition-all pointer-events-none hidden lg:block group-hover:bg-blackAlpha-400 group-hover:h-full group-hover:w-full" />
              )}
            </>
          )}
        </AspectRatio>
        {inView && <CategoryArticle value={article.category} className="absolute top-2 left-2" />}
      </RoutedDialogLink>
      <div
        onClick={() => {
          triggerRoutedDialog({
            name: "articleDetailExplore",
            state: {
              articleId: article.id,
              filters: {
                historyPath,
              },
            },
            type: typeRedirectRouter,
          })
        }}
        className="flex flex-col p-4 bg-atherGray-850 rounded-b-2xl flex-1"
      >
        <p className="font-semibold mb-2 flex-1 text-atherGray-0 line-clamp-2">{article.title}</p>
        <UserCreated
          username={article.creator.username}
          name={article.creator.name}
          createdAt={article.createdAt}
          picture={article.creator.picture}
        />
      </div>
      {!hiddenMenu && inView && <ActionButtonsArticle article={article} user={user} />}
    </div>
  )
}

export default memo(
  ArticleItem,
  ({ ...prevProps }, { ...nextProps }) => JSON.stringify({ prevProps }) === JSON.stringify(nextProps),
)
