import { ArticleDetail, ArticleMetric as ArticleMetricType, ArticleStatus, Reaction } from "@/api/sdk"
import { currency } from "@/utils"
import React from "react"
import { MdBookmarkBorder, MdChatBubbleOutline, MdOutlineInsertEmoticon, MdOutlineRemoveRedEye } from "react-icons/md"
import IconValue from "./IconValue"
import {
  BookmarkIcon,
  SmileIcon,
  CommentIcon,
  EyeIcon,
  FavoriteIcon,
  FavoriteFillIcon,
} from "@/components/shared/icons"
import IconButton from "@/components/IconButton"
import { googleAnalytics } from "@/lib/gtag"
import { useExploreBookmarkArticleMutation, useExploreReactionArticleMutation } from "@/queries"
import { useAuth } from "@/providers/authContext"
import { useSignInStore } from "@/stores"
import classNames from "classnames"

const ArticleMetric = ({ article }: { article: ArticleDetail }) => {
  const { user } = useAuth()
  const setSignInModal = useSignInStore(state => state.setSignInModal)
  const { mutate: mutateBookmark } = useExploreBookmarkArticleMutation()

  const { mutate: mutateEmotion } = useExploreReactionArticleMutation()

  const handleReaction = (reaction: Reaction) => {
    if (!user) {
      setSignInModal({
        signIn: true,
      })
      return
    }

    googleAnalytics.handleCategoryEvent({
      action: "click",
      params: {
        action: "Reaction article",
        tab_name: "articles",
        article_id: article.id,
        article_title: article.title,
      },
    })

    mutateEmotion({
      data: {
        reaction,
      },
      id: article?.id ?? "",
      userUid: user?.uid,
    })
  }

  const handleBookmark = () => {
    if (!user) {
      setSignInModal({
        signIn: true,
      })
      return
    }

    googleAnalytics.handleCategoryEvent({
      action: "click",
      params: {
        action: "Bookmark article",
        tab_name: "articles",
        article_id: article.id,
        bookmark: !article.favoritedByUser ? "true" : "false",
        article_title: article.title,
      },
    })

    mutateBookmark({
      id: article?.id ?? "",
      userUid: user?.uid,
    })
  }

  return (
    <div
      className="absolute bottom-0 left-0 p-2 w-full flex items-center justify-between text-sm"
      style={{
        background: "linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.70) 100%)",
      }}
    >
      <div className="flex space-x-1">
        <IconButton
          onClick={e => {
            e.stopPropagation()
            e.preventDefault()
            handleBookmark()
          }}
          colorScheme="transparent"
          disabled={article.status !== ArticleStatus.PUBLISHED}
          className={"flex bg-transparent items-center justify-center p-1 min-w-[2.2rem] text-sm font-normal"}
        >
          <IconValue
            className="text-xs"
            icon={
              <BookmarkIcon
                width={16}
                height={16}
                className={classNames("", {
                  "text-atherPurple-500": article?.favoritedByUser,
                  "text-atherGray-0": !article?.favoritedByUser,
                })}
              />
            }
            value={article?.metric.favoriteCount ?? 0}
          />
        </IconButton>
        <IconButton
          className={classNames(
            "flex bg-transparent items-center justify-center p-1 min-w-[2.2rem] text-sm font-normal",
          )}
          colorScheme="transparent"
          disabled={article.status !== ArticleStatus.PUBLISHED}
          onClick={e => {
            e.stopPropagation()
            e.preventDefault()
            handleReaction(Reaction.HEART)
          }}
        >
          <IconValue
            className="p-1 text-xs"
            icon={
              !article?.userReactions?.find(i => i.reaction === Reaction.HEART) ? (
                <FavoriteIcon width={16} height={16} />
              ) : (
                <FavoriteFillIcon width={16} height={16} />
              )
            }
            value={article ? article.metric.heartCount : 0}
          />
        </IconButton>
        {/* <IconValue icon={<BookmarkIcon width={16} height={16} />} value={metric.favoriteCount} />
        <IconValue icon={<FavoriteIcon width={16} height={16} />} value={metric.heartCount} /> */}
        <IconValue className="p-1" icon={<CommentIcon width={16} height={16} />} value={article.metric.commentCount} />
      </div>
      <div className="ml-2">
        <IconValue icon={<EyeIcon width={16} height={16} />} value={article.metric.viewCount} />
      </div>
    </div>
  )
}

export default ArticleMetric
