import IconButton from "@/components/IconButton"
import React, { useState } from "react"
import { QuillWriteIcon } from "@/components/shared/icons/"
import { useAuth } from "@/providers/authContext"
import { useExploreArticlesInfiniteQuery } from "@/queries"
import { ArticleCategory, ArticleStatus, CreateArticleDto } from "@/api/sdk"
import DraftListModal from "./Article/DraftListModal"
import useCustomRouter from "@/hooks/useCustomRouter"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import client from "@/api/client"
import { googleAnalytics } from "@/lib/gtag"
import _isEmpty from "lodash/isEmpty"

export type FeaturesModalType = "create-article" | null | "draft-list"

const GalleryFeaturesAction = () => {
  const [modal, setIsModal] = useState<FeaturesModalType>(null)
  const { user, handleSignIn } = useAuth()
  const router = useCustomRouter()
  const qc = useQueryClient()
  const { openNewTab } = useCustomRouter()

  const { mutate: mutateCreate, isPending: isLoading } = useMutation({
    mutationFn: (data: CreateArticleDto) => client.api.articleControllerCreateArticle(data).then(res => res.data),
    onSuccess: data => {
      setTimeout(() => {
        openNewTab(`/explore/articles/${data.id}`)
      }, 1)
      qc.invalidateQueries({ queryKey: ["infinite-explore-articles"] })
    },
  })

  const handleOpenModal = async (modal: FeaturesModalType) => {
    const isSignIn = await handleSignIn()
    if (!isSignIn) {
      return
    }

    googleAnalytics.handleCategoryEvent({
      action: "click",
      params: {
        action: "Create article",
        tab_name: "articles",
      },
    })

    if (modal === "create-article") {
      mutateCreate({
        title: "New Article",
        description: "",
        category: ArticleCategory.NEWS,
        status: ArticleStatus.DRAFT,
      })

      return
    }

    setIsModal(modal)
  }

  const { flattenData, fetchNextPage, hasNextPage, isFetching } = useExploreArticlesInfiniteQuery({
    variables: {
      creatorUid: user?.uid,
      onlyMine: true,
      statuses: [ArticleStatus.DRAFT],
    },
  })

  return (
    <div className="h-full flex flex-col">
      <div className="relative py-14 border-dashed border-[2px] border-atherGray-700 min-h-[10rem] text-atherGray-300 flex-col flex-1 w-full rounded-2xl flex items-center justify-center">
        <IconButton
          isLoading={isLoading}
          onClick={() => handleOpenModal("create-article")}
          className="flex flex-col items-center p-3 text-sm font-semibold rounded-full "
        >
          <QuillWriteIcon />
        </IconButton>
        <p className="mt-2"> Write an article</p>
      </div>
      {user && flattenData && flattenData?.length > 0 && (
        <button
          type="button"
          onClick={() => handleOpenModal("draft-list")}
          className="bg-atherGray-850 p-4 rounded-2xl text-sm font-semibold flex items-center justify-center mt-4 text-atherGray-300"
        >
          My Draft ({flattenData.length})
        </button>
      )}
      <DraftListModal
        isOpen={modal === "draft-list"}
        onClose={() => {
          setIsModal(null)
        }}
        data={flattenData}
        isFetching={isFetching}
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
      />
    </div>
  )
}

export default GalleryFeaturesAction
