import { capitalize } from "@/utils"
import { twMerge } from "tailwind-merge"

interface CategoryArticleProps {
  value: string
  className?: string
}

const CategoryArticle = ({ value, className }: CategoryArticleProps) => {
  return (
    <div className={twMerge("text-xs", className)}>
      <div className="bg-atherPurple-500 py-0.5 px-2 rounded-xl">
        <p className="font-[300] text-atherGray-0">{capitalize(value.toLowerCase())}</p>
      </div>
    </div>
  )
}

export default CategoryArticle
