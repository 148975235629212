import { ArticleDetail } from "@/api/sdk"
import Modal from "@/components/Modal"
import { motion } from "framer-motion"
import React, { useRef } from "react"
import InfiniteScroll from "react-infinite-scroller"
import ArticleItem from "../../ArticleItem"
import { useAuth } from "@/providers/authContext"
import GalleryNotFound from "../../GalleryNotFound"
import useCustomRouter from "@/hooks/useCustomRouter"
import { useBrowserRouter } from "@/providers/BrowserRouterProvider"
import GridBreakpointColsContainer from "@/components/GridBreakpointColsContainer"

interface DraftListModalProps {
  isOpen: boolean
  onClose: () => void
  data?: ArticleDetail[]
  isFetching: boolean
  hasNextPage: boolean
  fetchNextPage: () => void
}

const DraftListModal = ({ isOpen, onClose, data, fetchNextPage, hasNextPage, isFetching }: DraftListModalProps) => {
  const ref = useRef<HTMLDivElement>(null)
  const { user } = useAuth()
  const { asPath } = useBrowserRouter()

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        if (asPath.includes("/explore/articles/")) return
        onClose()
      }}
      title={`My Draft (${data?.length ?? 0})`}
      bodyClassName="overflow-hidden p-0"
      className="relative w-full max-w-[80rem] min-h-full my-0 overflow-hidden rounded-none"
      containerClassName={"md:backdrop-blur-md overflow-hidden"}
    >
      <div className="overflow-auto" ref={ref}>
        {data && data.length === 0 ? (
          <GalleryNotFound text="No items to show" />
        ) : (
          <InfiniteScroll
            loadMore={() => fetchNextPage()}
            hasMore={!!hasNextPage && !isFetching}
            getScrollParent={() => ref.current}
            useWindow={false}
            threshold={400}
            style={{
              display: "flex",
              flexDirection: "column",
              maxWidth: "100%",
              position: "relative",
            }}
            loader={
              <div key="loader" className="flex items-center justify-center py-4">
                Loading...
              </div>
            }
          >
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.35, delay: 0.35 }}
              className={"relative p-2 md:p-4"}
            >
              <GridBreakpointColsContainer>
                {data?.map(article => <ArticleItem key={article.id} data={article} user={user} />)}
              </GridBreakpointColsContainer>
            </motion.div>
          </InfiniteScroll>
        )}
      </div>
    </Modal>
  )
}

export default DraftListModal
